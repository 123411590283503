import com from "../3D/Component 2.png";
import cv from "../3D/Bara Barakat Full Stack.pdf";
// import cv from "../3D/pr/src/3D/pr/src/3D/Bara Barakat Front End.pdf";

export default function Id() {
  return (
    <div className="container">
      <img src={com} alt="img" />
      <div className="text">
        <h1>
          Full-Stack Developer (<h4>React/php</h4>)
        </h1>
        <p>
          We specialize in website programming and development, providing clean
          code and pixel-perfect design I can create any project you want in a
          very short time.
        </p>
        <div className="a">
          <a href={cv} target="_blank" rel="noopener noreferrer">
            <p>Download CV</p>
          </a>
        </div>
      </div>
    </div>
  );
}
