import Bim from "../3D/Bara.png";

export default function Nav() {
  return (
    <nav className="navbar">
      <img src={Bim} alt="B" />
      <div className="navbar-r">
        <a href="#My Skills">My Skills</a>
        <a href="#Projects">Projects</a>
        <a href="#Contact">Contact Me</a>
      </div>
    </nav>
  );
}
