import "./App.css";
import Nav from "./CO/Nav";
import Id from "./CO/Id";
import Wo from "./CO/WorkE";
import Pr from "./CO/Proj";
import Cert from "./CO/Cert";
import MySkills from "./CO/MySkills";
import Footer from "./CO/Footer";

function App() {
  return (
    <div className="App">
      <Nav />
      <Id />
      <Wo />
      <Pr />
      <Cert />
      <MySkills />
      <Footer />
    </div>
  );
}

export default App;
