import React, { useEffect, useState, useRef } from "react";
import DataCe from "./DataCe";

export default function Cert() {
  return (
    <div className="container">
      <h2>Certificat</h2>
      <div className="con">
        <div className="con1">
          {DataCe.map((dm, index) => (
            <DC img={dm.img} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentImgRef = imgRef.current;

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : null}
      alt={alt}
      style={{ opacity: isVisible ? 1 : 0, transition: "opacity 0.5s" }}
    />
  );
};

const DC = ({ img }) => {
  return <LazyImage src={img} alt="Certificat" />;
};
