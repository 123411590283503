import C1 from "../3D/Component 1.png";
// import arr from "../3D/Arrow.png";
export default function MySkills() {
  return (
    <div id="My Skills" className="containers">
      {/* <h2>My Skills</h2> */}
      {/* <img className="arr" src={arr} alt="img" /> */}
      <img src={C1} alt="img" />
    </div>
  );
}
