import Tx from "../3D/Work Experience.png"
import cx from "../3D/Component sk.png"

export default function WorkE(){
    return (
        <div className="work">
            <img src={Tx} alt="img" />
            <img src={cx} alt="img" />
        </div>
    )
}