import Co from "../3D/pp/Coffee.png";
import We from "../3D/pp/weather.png";
import React, { useEffect, useState, useRef } from "react";
import DataPro from "./DataPro";

export default function Proj() {
  return (
    <div id="Projects" className="proj">
      <h2>Projects</h2>
      <div className="item">
        <img className="img1" src={Co} alt="Coffee" />
        <img className="img2" src={We} alt="Weather" />
        <div className="wi">
          <div className="marquee">
            {DataPro.map((project) => (
              <div className="con" key={project.text}>
                <LazyImage src={project.img} alt="Project" />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={project.link}
                >
                  {project.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentImgRef = imgRef.current;

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : undefined}
      alt={alt}
      style={{ opacity: isVisible ? 1 : 0, transition: "opacity 0.5s" }}
    />
  );
};
