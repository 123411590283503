import cruds from "../3D/pp/cruds.png";
// import coffee from "../3D/pp/coffee.png";
import cara from "../3D/pp/cara.png";
import carency from "../3D/pp/carency.png";
import landing from "../3D/pp/landing.png";
import tic from "../3D/pp/tic.png";
// import weather from "../3D/pp/weather.png";
import Ai from "../3D/pp/ai.png";
import Wi from "../3D/pp/win.png";
import cl from "../3D/pp/clock.png";
import li from "../3D/pp/listin.png";

const DPro = [
  //   {
  //     id: 1,
  //     img: weather,
  //     link: "https://bara-barakat.github.io/Weather-App/",
  //     text: "This program aims to provide accurate and up-to-date information about the weather in selected cities.",
  //   },
  {
    id: 2,
    img: cruds,
    link: "https://bara-barakat.github.io/Cruds/",
    text: "CRUDS ",
  },
  //   {
  //     id: 3,
  //     img: coffee,
  //     link: "https://github.com/Bara-barakat/Coffee",
  //     text: "Coffee",
  //   },
  {
    id: 4,
    img: carency,
    link: "https://bara-barakat.github.io/Currency-Conversion./",
    text: "currencies relative to USD.",
  },
  { id: 5, img: Ai, link: "#", text: "Ai Images Generator" },
  { id: 6, img: Wi, link: "#", text: "Windows" },
  {
    id: 7,
    img: cara,
    link: "https://bara-barakat.github.io/Ecommerce/",
    text: "The Cara website",
  },
  {
    id: 8,
    img: tic,
    link: "https://bara-barakat.github.io/Tic-Tac/",
    text: "Tic Tac Toe.",
  },
  {
    id: 9,
    img: landing,
    link: "https://bara-barakat.github.io/landing-page/",
    text: "Landing page.",
  },
  { id: 10, img: li, link: "#", text: "To Do List" },
  { id: 11, img: cl, link: "#", text: "Clock" },
];

export default DPro;
