import Agile from "../3D/ce/Agile.jpg";
import Mc1 from "../3D/ce/Screenshot3.png";
import Mc2 from "../3D/ce/Screenshot.png";
import p from "../3D/ce/P.jpg";
import Mt from "../3D/ce/Mt.jpg";
import C from "../3D/ce/c++.png";
import H from "../3D/ce/H.jpg";
import HP from "../3D/ce/hp.png";
import it from "../3D/ce/IT.png";
import sd from "../3D/ce/SD.png";
import ibm from "../3D/ce/ibm.png";
import meta from "../3D/ce/Meta.png";
import php from "../3D/ce/php sec.png";
import jira from "../3D/ce/jira.png";
import ui from "../3D/ce/UI  UX.jpg";
import ajs from "../3D/ce/4313693e.jpg";

const DataCe = [
  { id: 1, img: ibm },
  { id: 2, img: it },
  { id: 3, img: sd },
  { id: 4, img: meta },
  { id: 5, img: php },
  { id: 6, img: Agile },
  { id: 7, img: jira },
  { id: 8, img: Mc1 },
  { id: 9, img: Mc2 },
  { id: 11, img: p },
  { id: 12, img: ui },
  { id: 13, img: ajs },
  { id: 14, img: HP },
  { id: 15, img: Mt },
  { id: 16, img: C },
  { id: 17, img: H },
];

export default DataCe;
