import gmail from "../3D/gmail-re.png";
import github from "../3D/github.png";
import linkd from "../3D/linkd.png";
import wats from "../3D/wats.png";

export default function Footer() {
  return (
    <div id="Contact" className="contact">
      <h2>Contact Me</h2>
      <div className="contact-Me">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://barabarakat700@gmail.com"
        >
          <img src={gmail} alt="img" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/Bara-barakat?tab=repositories"
        >
          <img src={github} alt="img" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/bara-barakat-330418296"
        >
          <img src={linkd} alt="img" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://wa.link/6ffdlp">
          <img src={wats} alt="img" />
        </a>
      </div>
    </div>
  );
}
